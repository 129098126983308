@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.cdnfonts.com/css/raleway-5');

:root{
    /*PRINCIPAL*/
    --principal-color : #774669;
    --principal-color-text : white;
    --shadow-principal-color : #7746693e;
    --navbar-color : #774669;

    /*SECONDARY*/
    --secondary-color : white;
    --secondary-color-text : black;

    /*TERTIARY*/
    --tertiary-color : #1cabc8;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid var(--principal-color); /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

html{
    scroll-behavior: smooth;
}
section[id]{
    scroll-margin-top: 120px;
}
.section-nosotros{
    display: flex;
    align-items: center;
    color: var(--principal-color-text);
    padding: 1rem;
    margin: auto;
    font-weight: 300;
    margin-top: 1.5rem;
    font-family: 'Poppins', sans-serif; 
}
.container-nosotros{
    display: flex;
    width: 100%;    
}
.nosotros-datos{
    background: linear-gradient(hsla(317, 26%, 37%, 0.63),hsla(317, 26%, 37%, 0.63)), url(../img/nosotrosRV.webp);
    background-size: cover;
    width: 55%;
    box-sizing: border-box;
    padding: 3rem;
}
.nosotros-text{
    white-space: pre-wrap;
    padding: 1rem;
    font-size: 1.5rem;
    text-align: justify;
    font-weight: 300;
    border: solid 5px var(--principal-color-text);
}
.nosotros-caracteristicas{
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--secondary-color);
    width: 45%;
}
.caracteristica-img{
    width: 150px;
    height: 150px;
    filter: brightness(1.1);
    mix-blend-mode: multiply;
}
.detalle-span{
    border-bottom: solid var(--tertiary-color) .2rem;
    font-weight: 900;
    font-size: 1.5rem;
}
.nosotros-caracteristica{
    color: var(--secondary-color-text);
    display: flex;
    justify-content: start;
    align-items: center;
    box-sizing: border-box;
    padding: 1.5rem;
    text-align: center;
    width: 95%;
    min-height: 15rem;
    font-size: 1.5rem;
    background: #fbf4f9;
    margin: .5rem;
}
.caracteristica-detalle{
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: .5rem;
}
.servicios-title, .contactanos-title{
    text-shadow: 0  .1rem .1rem var(--principal-color);
    text-align: center;
    margin: 0rem auto;
    font-size: 4rem;
    color : var(--principal-color);
    font-weight: bold;
    text-transform: uppercase;
}
.nosotros-title, .tarifas-title{
    color: var(--principal-color-text);
    text-shadow: 0rem  .1rem .1rem var(--principal-color-text);
    text-align: center;
    margin: 0 auto;
    font-size: 4rem;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif; 
}

.section-servicios{
    font-family: 'Poppins', sans-serif; 
    background: var(--secondary-color);
}
.servicio-span{
    text-align: center;
    font-weight: bold;
    color: var(--principal-color-text);
    font-size: 1.5rem;
    background: #535878;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    width: 100%; 
    padding: .2rem .5rem; 
    box-sizing: border-box;
}
.servicio-text{
    overflow-y: auto;
    background: #f2f4fb;
    min-height: 20rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    font-size: 1.5rem;
    padding-right: .5rem;
}

.container-servicios{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.servicio-datos{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: .5rem;
    box-sizing: border-box;
}

.servicio{
    width: 40rem;
    height: 55rem;
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    margin: 2rem;
}
.servicio img{
    width: 90%;
    filter: brightness(1.1);
    mix-blend-mode: multiply;
}
.section-tarifas{
    text-align: center;
    background: var(--principal-color);
    padding: 3rem 0;
    color: var(--principal-color-text);
    margin-top: -4px;
}
.table{
    width: 90%;
    text-align: center;
    font-family: 'Poppins', sans-serif; 
    margin: 1.5rem auto;
}
.horario-table{
    width: 100%;
    display: grid;
    place-content: center;
}
.columna-dias, .columna-hora{
    padding: .5rem;
}
.vehiculo-columna{
    border-bottom: solid 1px var(--tertiary-color);
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 1.5rem;
    padding: .2rem 0; 
    text-transform: uppercase;
}
.precio-columna{
    padding: 0 .5rem;
    font-size: 1.5rem;
    border-bottom: solid 1px var(--tertiary-color);
}
.cabecera-table{
    padding: .5rem;
    font-size: 1.1rem;
    background: var(--tertiary-color) ;
}
.icon-link-map{
    cursor: pointer;
    color: red;
    font-size: 2rem;
}
.btn-map{
    filter: drop-shadow(0 0.125rem 0.313rem rgba(0,0,0,.7));
    position: fixed;
    line-height: 55px;
    bottom: 17%;
    right: 1%;
    border-radius: 50px;
    opacity: .6;
    cursor: pointer;
    transition: all .1s ease;
    z-index: 999;
}
.btn-map:hover{
    transform: scale(1.1);
    opacity: 1;
}

.btn-wsp{
    filter: drop-shadow(0 0.125rem 0.313rem rgba(0,0,0,.7));
    position: fixed;
    line-height: 55px;
    bottom: 8%;
    right: 1%;
    border-radius: 50px;
    opacity: .6;
    cursor: pointer;
    transition: all .1s ease;
    z-index: 999;
}
.btn-wsp img, .btn-map img{
    width: 4rem;
    height: 4rem;
}
.btn-wsp:hover{
    transform: scale(1.1);
    opacity: 1;
}
.section-contactenos{
    display: flex;
    flex-direction: column;
}
.container-map{
    min-width: 90%;
    height: 500px;
    margin-bottom: 1.5rem;
    padding: 1rem;
}
.leaflet-container{
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
}
.container-contactanos{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    padding: 1.5rem 1rem;
    margin-top: 10px;
}
.contactanos-text{
    font-size: 1.2rem;
}
.contactanos-formulario-inputs{
    border-radius: 10px;
    width: 60%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 1.5rem;
    align-items: center;
    border-top: solid 2px var(--navbar-color) ;
    border-bottom: solid 2px var(--navbar-color) ;
}
.input-item{
    font-size: 1em;
    width: 70%;
    margin: 0;
    outline: none;
    height: 3.5rem;
    box-sizing: border-box;
    background: var(--shadow-principal-color);
    border: none;
    padding: 0 1rem;
    color: var(--secondary-color-text);
    font-family: 'Poppins','sans-serif';
}
.formulario-input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem;
    width: 100%;
}
.span-input{
    font-size: 1.5em;
}
.btn-enviar{
    width: 30%;
    height: 3rem;
    font-size: 1.2rem;
    font-family: 'Raleway', sans-serif;
    font-weight: bolder;
    cursor: pointer;
    margin: .5rem;
    border: none;
    background-color: var(--principal-color);;
    color: var(--principal-color-text);
    transition: .2s all ease;
    border-radius: 10px;
    box-shadow: 0 .20rem .25rem black;
}
.btn-enviar:hover{
    background-color: var(--shadow-principal-color);;
}
@media (max-width: 1523px){
    .caracteristica-img{
        width: 90px;
        height: 90px;
    }
    .caracteristica-detalle{
        font-size: 1rem;
    }
    .detalle-span{
        font-size: 1rem;
        font-weight: bold;
    }
    .btn-map, .btn-wsp{
        right: 3%;
    }
    .btn-wsp img, .btn-map img{
        width: 3rem;
        height: 3rem;
    }
    .nosotros-title, .contactanos-text{
        font-size: 2rem;
    } 
    .nosotros-title{
        margin: 1rem auto;
        width: 100%;
    }    
    .contactanos-text{
        padding: 0 1rem;
    }   
    .section-servicios{
        margin-bottom: 4rem;
    }
    .servicio{
        font-size: 1.1em;
        width: 100%;
        margin-bottom: 1rem;
        padding: 0;
    }
    .servicio img{
        width: 60%;
    }
    .servicio-text, .servicio-span{
        width: 100%;
    }
    .servicio-datos{
        width: 100%;
    }
    .caracteristica-detalle{
        margin: 1rem;
    }
    .formulario-input{
        width: 90%;
    }
    .contactanos-formulario-inputs{
        width: 80%;
    }
    .input-item{
        font-size: .75em;
        height: 2rem;
        width: 75%;
    }
    .span-input{
        font-size: 1rem;
    }
    .contactanos-text{
        text-align: center;
    }
    .vehiculo-columna, .precio-columna{
        font-size: 1rem;
        justify-content: center;
    }
    .cabecera-table{
        font-size: .75rem;
    }
    .leaflet-container{
        border-radius: 20px;
    }
    .container-map{
        height: 300px;
    }
}
@media (max-width: 1206px){   
    .columna-dias, .columna-hora{
        padding: 0;
    }
    .container-nosotros{
        flex-direction: column;
    }
    .section-nosotros{
        padding: 0;
    }
    .nosotros-datos{
        background: var(--principal-color);
        width: 100%;
        padding: .5rem;
    }
    .nosotros-text{
        border: none;
        font-size: 1rem;
    }
    .nosotros-caracteristicas{
        width: 100%;
    }
    .nosotros-caracteristica{
        width: 100%;
    }
    .caracteristica-img{
        width: 150px;
        height: 150px;
    }
    .caracteristica-detalle{
        font-size: 1.5rem;
    }
    .detalle-span{
        font-size: 1rem;
    }
    .servicio-datos{
        width: 100%;
    }
    .servicio{
        width: 95%;
        margin: 1rem 0;
    }
    .servicio img{
        width: 70%;
    }
}

@media (max-width: 500px){
    .nosotros-datos{
        font-size: .6rem;
    }
    .nosotros-caracteristica{
        height: 150px;
        padding: 1 .1rem;
        font-size: 1.2em;    
        gap: 0;   
    }
    .caracteristica-img{
        width: 60px;
        height: 60px;
    }
    .container-icon-map{
        width: 7%;
    }
    .container-icon-map span{
        display: none;
    }
    .caracteristica-detalle{
        font-size: .75rem;
    }
    .servicios-title, .contactanos-title, .tarifas-title, .nosotros-title{
        font-size: 2rem;
        width: 100%;
        margin-bottom: 1rem;
    }
    .servicio{
        font-size: .75rem;
        margin-bottom: 2.5rem;
        margin-left: 0;
        margin-right: 0;
        height: 33rem;
    }
    .servicio-text{
        font-size: 1rem;
        /* background: red; */
        height: 17rem;
    }
    .servicio img{
        width: 60%;
    }
    .caracteristica-img{
        width: 30%;
    }
    .input-item{
        width: 60%;
    }
    .vehiculo-columna, .precio-columna{
        font-size: .75rem;
    }
    .contactanos-text{
        font-size: .75rem;
    }
}


