.carousel-principal{
    width: 98%;
    padding: .5rem;
    background: white;
    box-sizing: border-box;
    margin: 0 auto;
}
.img-principal-carousel{
    height: 800px;
    width: 90%;
    object-fit: cover;
    margin: 0 auto;
    border-radius: 10px;
    opacity: .9;
    filter: contrast(120%);
}
.slickk-arrow{
    color: white;
}

@media (max-width: 950px){
    .img-principal-carousel{
        height: 250px 
    }
    .carousel-principal{
        width: 90%;
    }
}

